<template>
     <div class="container-fluid">
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="true"/>
          <div class="row row-custom">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title">{{(IsNew)?'اضافة مستخدم جديد':'تعديل المستخدم'}}</h4>
                  <p class="card-category">{{(IsNew)?'سيتم اضافة مستخدم للنظام ':'سيتم تعديل على مستخدم في النظام'}}</p>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <Input id="name" title="اسم المستخدم" @Input="HandleInput('name',$event)" Mykey="name" :errors="service_error" :MyValue="form.name"/>
                        <Input id="email" title="البريد الإلكتروني" @Input="HandleInput('email',$event)" Mykey="email" :errors="service_error" :MyValue="form.email"/>
                        <Select id="Role" title="الدور الوظيفي" :Items="Roles" Mykey="Role" :errors="service_error" :MyValue="form.Role+''" @Select="HandleSelect('Role',$event)"/>
                        <Input id="password" title="كلمة المرور" @Input="HandleInput('password',$event)" Mykey="password" :errors="service_error" :MyValue="form.password" MyType="password"/>
                        <Input id="password_confirmation" title="تاكيد كلمة المرور" @Input="HandleInput('password_confirmation',$event)" Mykey="password_confirmation" :errors="service_error" :MyValue="form.password_confirmation" MyType="password"/>

                    </div>

                    <template v-if="!IsAdmin">
                    <div class="card text-right">
                        <div class="card-header">
                           الصلاحيات
                        </div>
                        <div class="card-body">
                          <div class="row">   
                          
                          <InputToggle v-model="PermissionsToggleArray[index].val" :MyValue="PermissionsToggleArray[index].val+''" v-for="(Permission,index) in CustomPermissions" :key="index"  :id="Permission.id+''" :title="getTranslate(Permission.name)" :errors="service_error" 
                          @GiveMeIfo="HandlePermissions($event,index)"
                           />
                          
                          </div>                      
                        </div>
                    </div>

                    <div class="card text-right">
                        <div class="card-header">
                           اجراءات
                        </div>
                        <div class="card-body">
                         <InputToggle v-model="ProcedurePermissionsToggleArray[index].val" :MyValue="ProcedurePermissionsToggleArray[index].val+''" v-for="(Permission,index) in ProcedurePermissions" :key="index"  :id="Permission.id+''" :title="getTranslate(Permission.name)" :errors="service_error" 
                          @GiveMeIfo="HandleProcuderPermissions($event,index)"/>
                                                    
                        </div>
                    </div>
                    </template>

                    <button type="button" class="btn btn-primary pull-right" v-if="IsNew" @click="onSubmit()">اضافة</button>
                    <button type="button" class="btn btn-primary pull-right" v-if="!IsNew" @click="onUpdate()">تعديل</button>
                    <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import Input from '../../../components/Form/TextInput'
import Select from '../../../components/Form/TextSelect'
import InputToggle from '../../../components/Form/InputToggle'
import {mapState,mapMutations} from 'vuex';
import store from '../../../store/index'
import SuccessAlert from '../../../components/SuccessAlert'
export default {
  computed:{
    ...mapState({
        User:state=>state.admin.users.User.data.data,
        service_error:state=>state.admin.users.User.error,
        Roles:state=>state.admin.users.Roles,
        Permissions:state=>state.admin.users.Permissions,

    }),
    IsNew(){
      return this.form.id == '';
    },
    CustomPermissions(){
      return this.Permissions.filter(p=>(p.name !='can_add' && p.name !='can_edit' && p.name !='can_delete'));
   },
   ProcedurePermissions(){
      return this.Permissions.filter(p=>(p.name =='can_add' || p.name =='can_edit' || p.name =='can_delete'));
   },
   IsAdmin(){
     let result=this.Roles.find(r=>r.id ==this.form.Role);
     if(result){
       return (result.name == 'admin')?true:false;
     }else{
       return false;
     }
   },
  },
   data(){
       return{
           form:{
               id:'',
               name:'',
               email:'',
               Role:'',
               password:'',
               password_confirmation:'',
               SelectedPermissions:[],
           },
           
           PermissionsToggleArray:[],
           ProcedurePermissionsToggleArray:[],

           AlertTitle:'',
           AlertMessage:'',

       }
   },
   methods:{
    ...mapMutations({
          cleanErrors: "admin/cleanUserErrors"
       }),
     getTranslate(permission){
        const lang={
            'can_add':'اضافة',
            'can_edit':'تعديل',
            'can_delete':'حذف',

            'manage_colors':'الالوان',
            'manage_countries':'الدول',
            'manage_cities':'المدن',
            'manage_status':'الحالات',
            'manage_settings':'الاعدادات',
            'manage_advertisement':'الاعلانات',

            'manage_categories':'التصنيفات',
            'manage_sub_categories':'التصنيفات الفرعية',
            'manage_products':'المنتجات',
            'manage_orders':'الطلبيات',
            'manage_clients':'الزبائن',
            'manage_discount_code':'اكواد الخصم',
            'manage_users':'المستخدمين',
            
        };
        return lang[permission];
     },  
     setData(vm){
           vm.form.id=vm.User.id;
           vm.form.name=vm.User.name;
           vm.form.email=vm.User.email;
           vm.Roles.forEach(element => {
             if(element.name == vm.User.role[0] )
              vm.form.Role=element.id;
           });
           vm.User.permissions.forEach(element => {
            vm.form.SelectedPermissions.push(element.id);
          });
           

        },
       HandlePermissions(value,index){
         this.PermissionsToggleArray[index].val=value.val;
         if(value.status=="push"){
          this.form.SelectedPermissions.push(value.id); 
         }
         else{
           var index = this.form.SelectedPermissions.indexOf(value.id);
          if (index > -1) {
              this.form.SelectedPermissions.splice(index, 1);
          }
         }
         
       },
        HandleProcuderPermissions(value,index){
         this.ProcedurePermissionsToggleArray[index].val=value.val;
         if(value.status=="push"){
          this.form.SelectedPermissions.push(value.id); 
         }
         else{
           var index = this.form.SelectedPermissions.indexOf(value.id);
          if (index > -1) {
              this.form.SelectedPermissions.splice(index, 1);
          }
         }
         
       },


       
       onSubmit(){
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/CreateUser',this.form).then((response)=>{
            store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تمت الإضافة بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
            store.commit('admin/PleaseStopLoading');
          });
       },
       onUpdate(){
          store.commit('admin/PleaseStartLoading');
          let formData=new FormData(); 
          for (let key in this.form) { 
            if(key == 'SelectedPermissions'){
              for (let index = 0; index < this.form.SelectedPermissions.length; index++) {
                formData.append('SelectedPermissions['+index+']', this.form.SelectedPermissions[index]);
              }
            }else{
              formData.append(key, this.form[key]);
            }
            
          }
          formData.append('_method', "patch");
          store.dispatch('admin/updateUser',formData).then((response)=>{
                store.commit('admin/PleaseStopLoading');
                this.cleanErrors();
                this.AlertTitle="معلومات",
                this.AlertMessage="تمت التعديل بنجاح";
                this.$refs.MySuccessAlert.showModel();
          }).catch(()=>{
            store.commit('admin/PleaseStopLoading');
          });
       },  
     HandleInput(key,value){
         this.form[key]=value;
     },
     HandleSelect(key,value){
         this.form[key]=value;
     },
   },
   components:{
       Input,
       Select,
       SuccessAlert,
       InputToggle
   },

   
     beforeRouteEnter(to,from,next){    
       store.commit('admin/PleaseStopLoading');
      const id = to.params.id;
         store.dispatch('admin/fetchRoles').then(()=>{
          store.dispatch('admin/fetchPermissions').then(()=>{
        if (typeof id === "undefined") {
            next(vm => {
               //===Fill Toggle Array
               vm.Permissions.forEach(element => {
                    vm.PermissionsToggleArray.push({
                      id:element.id,
                      val:false,
                    });
                  });
                
                vm.ProcedurePermissions.forEach(element => {
                    vm.ProcedurePermissionsToggleArray.push({
                      id:element.id,
                      val:false,
                    });
                  });
                
            });
        } else {
            store.dispatch('admin/fetchUser', id).then((response) => {
                next(vm => {
                   
                vm.setData(vm);

                vm.Permissions.forEach(element => {
                    vm.PermissionsToggleArray.push({
                      id:element.id,
                      val:(vm.form.SelectedPermissions.indexOf(element.id) != -1),
                    });
                  });
                
                vm.ProcedurePermissions.forEach(element => {
                    vm.ProcedurePermissionsToggleArray.push({
                      id:element.id,
                      val:(vm.form.SelectedPermissions.indexOf(element.id) != -1),
                    });
                  });

                });


            });
        }
        }).catch((error)=>{
       next(false);
      });
         }).catch((error)=>{
       next(false);
      });
 

         

    },
      beforeDestroy(){
         this.cleanErrors();
     }
}
</script>

<style lang="scss" scoped>
/*
 *
 */
.row-custom{
    justify-content: center;
}

.card .card-header {
    background: #16918b;
    color: white;
}


.card-header{
text-align: right;
}
</style>